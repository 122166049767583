import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {onMounted,ref,computed} from 'vue' 
import PopupNotice from '@/components/PopupNotice.vue'
import BackHome from '@/components/BackHome.vue'
import homeService from '@/services/home'
import authService from '@/services/auth'
import {useStore} from 'vuex'
import {useRouter, useRoute} from 'vue-router'
import useCommon from '@/composables/useCommon'
import wx from 'weixin-js-sdk'
import userServices from '@/services/user'
import {getToken,getMarsToken} from '@/utils/auth'
import {isWeixin} from '@/utils/tools'



export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const { getProfile } = userServices;
const {JumpTo} = useCommon()
const showPopup = ref(false)

const router = useRouter()
const route = useRoute()
const store = useStore()
const {getInitConfig} = homeService
const {getWechatAuth} = authService
const popupList = ref([])
onMounted(async () => {
  wechatShare()
  try {
    // const {data} = await getInitConfig()
    // popupList.value = data.popups.reverse() || []
    // let platforms = data.platforms.map(v=>{
    //   return {
    //     text: v.name,
    //     value: v.name
    //   }
    // })
    // await store.dispatch('common/setPlatforms', platforms)
    console.log(111, route.name);
    
    // if (route.name!=='Login') {
    //   getConfigs()
    // }
    if (getToken() || getMarsToken()) { 
      const userData = await getProfile()
      await store.dispatch('user/setUserInfo', userData.data)
    }
  }catch(err){
    console.log('err',err)
  }
})

const getConfigs = async () => {
  try {
    if (route.name=='Login') return
    const res = await getInitConfig()
    if (res && res.data) {
      let data = res.data
      popupList.value = data.popups.reverse().filter(v=>{
        let url = decodeURI(window.location.href)
        // console.log('弹窗', v['page_url'], window.location.href, decodeURI(v['page_url']), decodeURI(window.location.href))
        return url.includes(decodeURI(v['page_url']) || '/home')
      }) || []
      // console.log('获取弹窗列表', popupList.value)
      let platforms = data.platforms.map(v=>{
        return {
          text: v.name,
          value: v.name
        }
      })
      await store.dispatch('common/setPlatforms', platforms)
    }
    

  } catch(err){
    console.log('err',err)
  }
}

const gotoViews = () => {
  JumpTo('Personal')
  showPopup.value = false
}
router.beforeEach((to,from,next) => {
  let user = store.state.user.user
  if (Object.keys(user).length && to.name!=='Login'&&to.name!=='Personal') {
    const userName = computed(()=>store.state.user.user.username)
    const mobile = computed(()=>store.state.user.user.mobile)
    console.log(store.state.user.user, userName.value, userName.value == mobile.value);
    
    if (!userName.value || userName.value == mobile.value)
    showPopup.value = true
  } 
  console.log('11111', to.name)
  if (to.name!='Login') {
    getConfigs()
  } 
  
  next()
})


async function wechatShare() {
  if (isWeixin()) {
    try {
      let res = await getWechatAuth()
      console.log('getWechatAuth',res.data.config)
      let data = res.data.config

      const appId = data.app_id
      const timestamp = data.timestamp
      const nonceStr = data.nonce_str
      const signature = data.signature

      // setAppId
      await store.dispatch('common/setAppId', appId)
      
      const banner = 'http://marsvision.oss-cn-beijing.aliyuncs.com/images/custom-resource/logo.png'

      const title = '火星视界'
      const detail = '达人最好用的内容变现平台'

      wx.config({
        debug: false,
        appId: appId,
        timestamp: timestamp,
        nonceStr: nonceStr,
        signature: signature,
        jsApiList: [
          'checkJsApi', 
          'updateTimelineShareData', // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
          'updateAppMessageShareData', // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
          'uploadImage', // 上传图片接口
          'downloadImage', // 下载图片接口
        ],
      })
      
      wx.ready(function () {
        wx.updateTimelineShareData({
          // “分享到朋友圈”及“分享到QQ空间”
          title: title, // 分享标题
          link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: banner, // 分享图标
        })
        wx.updateAppMessageShareData({
          // “分享给朋友”及“分享到QQ”
          title: title, // 分享标题
          desc: detail, // 分享描述
          link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: banner, // 分享图标
        })

        
      })

    } catch (error) {
      console.log(error)
    }
  }
}

return (_ctx: any,_cache: any) => {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_van_number_keyboard = _resolveComponent("van-number-keyboard")!
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_van_nav_bar, { "safe-area-inset-top": "" }),
    _createVNode(_component_router_view),
    _createVNode(_component_van_number_keyboard, { "safe-area-inset-bottom": "" }),
    _createVNode(PopupNotice, { popup: popupList.value }, null, 8, ["popup"]),
    _createVNode(_component_van_popup, {
      class: "common-notice-popup common-notice-popup-noicon",
      "close-on-click-overlay": false,
      show: showPopup.value,
      "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((showPopup).value = $event)),
      round: "",
      style: { padding: '64px' }
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", { class: "common-notice-popup-content" }, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "notice-popup-title" }, "修改博主名", -1)),
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "notice-popup-content" }, [
            _createElementVNode("div", { class: "notice-center-message" }, "为了您的商务更方便的与您沟通，请及时修改您的用户名")
          ], -1)),
          _createElementVNode("div", {
            class: "notice-popup-button",
            style: {"display":"flex"}
          }, [
            _createElementVNode("div", {
              class: "primary-button copy-mobile-btn",
              onClick: gotoViews
            }, "立即修改")
          ])
        ])
      ]),
      _: 1
    }, 8, ["show"]),
    _createVNode(BackHome)
  ], 64))
}
}

})