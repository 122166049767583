import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ApiClient from '@/plugins/api-client'
// import Vant from 'vant';
import { Button,Toast,Icon,Swipe,SwipeItem,Field,CellGroup,Picker,Popup,Switch,
  Form,Uploader,RollingText,Calendar,ImagePreview,CountDown,Dialog,Overlay, Checkbox } from 'vant';
import 'vant/lib/index.css';
import '@/assets/styles/index.scss'

console.log('process', process.env)

createApp(App)
.use(router).use(store)
.use(ApiClient)
.use(Button).use(Toast).use(Icon).use(Swipe).use(SwipeItem).use(Field).use(CellGroup).use(Picker).use(Popup).use(Switch)
.use(Form).use(Uploader).use(RollingText).use(Calendar).use(ImagePreview).use(CountDown).use(Dialog).use(Overlay).use(Checkbox)
.mount('#app')
