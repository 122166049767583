import ApiClient from '@/utils/api-client'
export default {
  // Banner 列表
  getHomeBanner(): any {
    return ApiClient.get(`/home/banners`)
  },
  // 系统通知列表
  // const NotificationTypeKeywordStats = "keyword_stats" // 数据更新通知  -属于系统通知
  // const NotificationTypeKeywordAudit = "keyword_audit" // 审核通知  -属于系统通知
  // const NotificationTypeOperation = "operation" // 运营通知
  getNotifications(param?: any): any {
    return ApiClient.get(`/notifications`, param)
  },
  // 强力推荐平台
  getPlatformRecommend(): any {
    return ApiClient.get(`/home/platforms/recommend`)
  },
  // /home/platforms
  // 所有平台
  getPlatforms(): any {
    return ApiClient.get(`/home/platforms`)
  },
  // APP 启动时请求配置
  getInitConfig(): any {
    return ApiClient.get(`/config`)
  },
  // 已经弹窗上报
  setReadPopup(dialog_id): any {
    return ApiClient.post(`/dialogs/${dialog_id}/popup`)
  },
  // 绑定 OpenID
  bindOpenId(params): any {
    return ApiClient.post(`/api/wechat/bound/open_id`, params)
  },
}
