import Store from '@/store'
import {setStoreState} from '../../utils'
const userActions = {
  async get(): Promise<any> {
    try {
      console.log(1);
      // setStoreState('user', 'user', res)
    } catch (error: any) {
      console.log(error)
      throw error
    }
  },
  async setUserInfo(context, user): Promise<any> {
    try {
      console.log(1,user);
      setStoreState('user', 'user', user)
    } catch (error: any) {
      console.log(error)
      throw error
    }
  },
  
}

type UserActionsType = typeof userActions

export {UserActionsType}
export default userActions
