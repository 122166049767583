import axios from 'axios'
import isArray from 'lodash/isArray'
import has from 'lodash/has'
import {removeToken,removeMarsToken} from '@/utils/auth'

import { showLoadingToast, closeToast, showToast } from 'vant';

const createInstance = () => {
  const instance = axios.create({
    timeout: 1000*60*2,
    withCredentials: true,
  })

  instance.interceptors.request.use(config => {
    config.url = '/api' + config.url
    return config
  })

  // 让使用者拿到数据而非原始响应
  instance.interceptors.response.use(
    response => {
      return response
    },
    error => {
      return Promise.reject(error)
    },
  )
  return instance
}

const createApiClient = () => ({
  instance: createInstance(),
  errorMiddleware: null,
  retryUrl: null,
  promiseCache: {},
  get(url: string, options = {}): Promise<any> {
    console.log('options', options);
    return this.request('get', url, null, {}, options)
  },
  put(url: string, data?: any, options = {}): Promise<any> {
    return this.request('put', url, data, options)
  },
  post(url: string, data = {}, options = {}): Promise<any> {
    return this.request('post', url, data, options)
  },
  patch(url: string, data: any, options = {}): Promise<any> {
    return this.request('patch', url, data, options)
  },
  delete(url: string, data: any = {}, options = {}): Promise<any> {
    return this.request('delete', url, data, options)
  },
  upload(url: string, data: any, files: any[], options = {}): Promise<any> {
    if (isArray(files)) {
      files = files.map((file: any) => ({name: 'source', data: file}))
    } else {
      files = [{name: 'source', data: files}]
    }

    const formData = new FormData()
    files.forEach(file => formData.append(file.name, file.data))
    data.forEach((value: any, key: string) => formData.append(key, value))

    return this.post(url, formData, {...options, forbidReuse: true, timeout: 60000})
  },
  request(method: string, url: string, data: any, options = {}, params?:any): Promise<any> {
    showLoadingToast({
      // message: '加载中...',
      duration: 0,
      forbidClick: true,
    });
    const param: any = {method, url, data, ...options, params}
    const promise: Promise<any> = this.instance
      .request(param)
      .then(response => {
        closeToast();
        console.log('then', response);
        
        if (has(response.data, 'code') && response.data.code !== 0) {
          return Promise.reject({response: {data: response.data.message, code: response.data.code}})
        } else {
          return this.handleSuccess(url, method, response)
        }
      })
      .catch(error => {
        closeToast();
        console.log('catch',error);
        if (error.message && error.message.includes('timeout of 30000ms exceeded')) {
          showToast('请求超时')
        }
        return this.handleError(url, method, data, options, error)
      })

    return promise
  },
  handleError(url: string, method: string, data: any, options: any, error: any): Promise<any> | void {
    const responseError = error && error.response && error.response.data
    const responseStatus = error && error.response && error.response.status

    if (responseError && responseError.error) {
      showToast(responseError.error.message)
    }
    if (responseStatus==401 && !location.href.includes('/login')) {
      removeToken()
      removeMarsToken()
      // setTimeout(() => {
        location.replace('/login')
      // }, 1000)

    }
    console.log('responseError', responseStatus, responseError);
    
    // return responseError
    // return Promise.reject(responseError.error)
  },
  handleSuccess(url: string, method: string, response: any): Promise<any> {
    if (response.data && response.data.message && response.data.message === 'success') {
      return Promise.resolve(response.data.data)
    } else {
      return Promise.resolve(response.data)
    }
  },
  // 设置参数
  setBaseUrl(baseURL: string): void {
    this.instance.defaults.baseURL = baseURL
  },
  setToken(token: string | null): void {
    this.instance.defaults.headers.common['Authorization'] = token
  },
  delToken(): void {
    this.instance.defaults.headers.common['Authorization'] = ''
  },
})

const ApiClient = createApiClient()
ApiClient.setBaseUrl(process.env.VUE_APP_BASE_URL as string)

// 创建一个不需要token的http请求
export const noTokenApiClient = createApiClient()
noTokenApiClient.instance.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  },
)

export default ApiClient
