import ApiClient from '@/utils/api-client'
export default {
  get(): any {
    return ApiClient.get(`/web/user/info`)
  },
  getUserInfo(): any {
    return ApiClient.get(``)
  },
  // 登录
  login(params): any {
    return ApiClient.post(`/login`,params)
  },
  // 退出
  logout(params?): any {
    return ApiClient.post(`/logout`,params)
  },
  // 发送验证码
  sendVcode(params): any {
    return ApiClient.post(`/send_vcode`,params)
  },
  // 用户信息编辑
  setProfile(params): any {
    return ApiClient.put(`/me/profile`,params)
  },
  // 获取个人信息
  getProfile(): any {
    return ApiClient.get(`/me/profile`)
  },
  // 修改密码 /me/password
  editPassword(params): any {
    return ApiClient.put(`/me/password`,params)
  },
  // 创建博主 
  createBlogger(params): any {
    return ApiClient.post(`/bloggers/register`,params)
  },
  // 商务主管获取绑定商务 
  getBindDirector(params): any {
    return ApiClient.get(`/directors/bind_director`,params)
  },
  // 商务主管创建团长 
  createDirectorBlogger(params): any {
    return ApiClient.post(`/directors/create_director_blogger`,params)
  },
  // 注册获取团长
  getFindDirector(params): any {
    return ApiClient.get(`/director`,params)
  },
  // 短信修改密码
  loginResetPassword(params): any {
    return ApiClient.post(`/change_password`,params)
  },
}
