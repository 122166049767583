<template>
  <div class="backhome" @click="router.push('/')" v-show="showBackHome">
    <img src="@/assets/images/tabs/home.svg" alt="">
  </div>
</template>
<script lang='ts' setup>
import {ref,computed} from 'vue'
import {useRoute,useRouter} from 'vue-router'

const router = useRouter()
const route = useRoute()

const list = ['Index','Discovery','Profit','Mine','UserAgreement','PrivacyPolicy','Register','Login']

const showBackHome = computed(() => {
  return list.indexOf(route.name) == -1
})

</script>
<style lang='scss' scoped>
.backhome {
  position: fixed;
  bottom: 100px;
  right: 12px;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #409eff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0 0 6px rgba(0, 0, 0, .12);
  cursor: pointer;
  z-index: 10;
  img {
    width: 24px;
  }
}
</style>