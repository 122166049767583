
import dayjs from 'dayjs'

export const formatSelectList = (list) => {
  return list.map(v=>{
    return {
      name: v,
      value: v
    }
  })
}

export const formatTime = (time) => {
  return dayjs(time).format('YYYY-MM-DD')
}

export const formatUnixTime = (time) => {
  return dayjs.unix(time).format('YYYY-MM-DD')
}

export const formatUnixTimeAll = (time) => {
  return dayjs.unix(time).format('YYYY-MM-DD HH:mm:ss')
}

export const getUnixRange = (day) => {
  return `${dayjs().add(-day,'day').unix()},${dayjs().unix()}`
}
export const formatUnixRange = (start,end) => {
  return `${dayjs(start).unix()},${dayjs(end).unix()}`
}
export const formatTimeText = (day) => {
  let text = ''
  switch(day) {
    case 7:
      text = '最近7天'
      break;
    case 14:
      text = '最近14天'
      break;
    case 30:
      text = '最近30天'
      break;
    
  }
  return text
}

export const formatNums = (num) => {
  console.log(num);
  
  return num ? num.toFixed(2) : ''
}


export const isWeixin = () => {
  if (/(micromessenger)/i.test(navigator.userAgent)) {
    return true
  } else {
    return false
  }
}

export const validatorPassword = (val) => /^(?=.*[a-zA-Z])(?=.*[\d]).{8,20}$/.test(val)
export const validatorMobile = (val) => /1\d{10}/.test(val);


export function generateRandomString() {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const stringLength = 10;
  let randomString = '';
  
  for (let i = 0; i < stringLength; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    randomString += chars.charAt(randomIndex);
  }
  
  return randomString;
}