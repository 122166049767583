import ApiClient from '@/utils/api-client'
export default {
  // 获取银行列表
  getBanks(): any {
    return ApiClient.get(`/banks`)
  },
  // 用户-获取银行卡
  getBankCards(): any {
    return ApiClient.get(`/me/bank_cards`)
  },
  // 用户-修改银行卡信息
  editBankCard(id, params): any {
    return ApiClient.put(`/me/bank_cards/${id}`, params)
  },
  // 用户-创建银行卡
  createBankCard(params): any {
    return ApiClient.post(`/me/bank_cards`, params)
  },
  
}
