import Store from '@/store'
import {setStoreState} from '../../utils'
const userActions = {
  async get(): Promise<any> {
    try {
      console.log(1);
      // setStoreState('user', 'user', res)
    } catch (error: any) {
      console.log(error)
      throw error
    }
  },
  async setToken(context, token): Promise<any> {
    try {
      console.log(1,token);
      setStoreState('common', 'token', token)
    } catch (error: any) {
      console.log(error)
      throw error
    }
  },
  async setAppId(context, appId): Promise<any> {
    try {
      console.log(1, appId);
      setStoreState('common', 'appId', appId)
    } catch (error: any) {
      console.log(error)
      throw error
    }
  },
  async setPlatforms(context, platforms): Promise<any> {
    try {
      setStoreState('common', 'platforms', platforms)
    } catch (error: any) {
      console.log(error)
      throw error
    }
  },
  
}

type UserActionsType = typeof userActions

export {UserActionsType}
export default userActions
