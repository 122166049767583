import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/tabs/home.svg'


import {ref,computed} from 'vue'
import {useRoute,useRouter} from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'BackHome',
  setup(__props) {

const router = useRouter()
const route = useRoute()

const list = ['Index','Discovery','Profit','Mine','UserAgreement','PrivacyPolicy','Register','Login']

const showBackHome = computed(() => {
  return list.indexOf(route.name) == -1
})


return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "backhome",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/')))
  }, _cache[1] || (_cache[1] = [
    _createElementVNode("img", {
      src: _imports_0,
      alt: ""
    }, null, -1)
  ]), 512)), [
    [_vShow, showBackHome.value]
  ])
}
}

})