import {computed} from 'vue'
import {useStore} from 'vuex'
import type {StateType} from '@/types'
import {useRouter, useRoute} from 'vue-router'

export default function useCommon() {
  
  const router = useRouter()
  const store = useStore<StateType>()
  
  const JumpTo = (name='/', params={}) => {
    router.push({name, query: params})
  }

  return {JumpTo}
}
