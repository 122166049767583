import {StateType} from '@/types'
import {Module} from 'vuex'

const state = {
  user: {} as any,
  token: '',
  appId: '',
  platforms: [],
}
type CommonStateType = typeof state

const common: Module<CommonStateType, StateType> = {
  namespaced: true,
  ...state,
}

export {CommonStateType, state}
export default common
