import Cookies from 'js-cookie'
const MarsTokenKey = 'mars-token'
const TokenKey = 'token'
const RefreshTokenKey = 'refresh-token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getMarsToken() {
  return Cookies.get(MarsTokenKey)
}

export function setMarsToken(token) {
  return Cookies.set(MarsTokenKey, token)
}

export function removeMarsToken() {
  return Cookies.remove(MarsTokenKey)
}

export function getRefreshToken() {
  return Cookies.get(RefreshTokenKey)
}

export function setRefreshToken(token) {
  return Cookies.set(RefreshTokenKey, token)
}

export function removeRefreshToken() {
  return Cookies.remove(RefreshTokenKey)
}

